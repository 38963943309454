import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import ImageUpload from '@/components/ImageUploadWithProgressBar/ImageUploadWithProgressBar.vue';
import TextArea from '@/components/TextArea/TextArea.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '../../../components/BottomButton/BottomButton.vue';
import ItemList from '@/components/ItemList/ItemList.vue';
import Modal from '@/components/Modal/Modal.vue';
import FormatMenu from '@/components/FormatMenu/FormatMenu.vue';
import SkeletonLoadingImage from '@/components/SkeletonLoadingImage/SkeletonLoadingImage.vue';
import AIPanel from '@/views/General/DecisionScreen/AIPanel/AIPanel.vue';

import DecisionService from '@/services/API/decision.service';
import TransferService from '@/services/API/transfer.service';
import socketClient from '@/services/SOCKET';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import CommonConst from '@/constants/CommonConst';
import DecisionConst from '@/constants/DecisionConst';
import autoHeightTextArea from '@/mixin/autoHeightTextArea';
import scrollToTop from '@/mixin/scrollToTop';
import deviceChecking from '@/mixin/deviceChecking';
import redDotNotifyMixin from '../../../mixin/redDotNotifyMixin.js';
import downloadPdfMixin from '../../../mixin/downloadPdfMixin.js';

import moment from 'moment';
import { mapState } from 'vuex';

const DEFAULT_ITEM = {
	title: undefined,
	isChanged: undefined,
	dateData: undefined,
	itemId: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	showDateData: true,
	showTitle: true,
	statusIcon: 'QuestionStatus.png',
	isShowIcon: false,
	isColorChange: true,
};

export default {
	name: 'DecisionScreen',
	mixins: [
		autoHeightTextArea,
		scrollToTop,
		deviceChecking,
		redDotNotifyMixin,
		downloadPdfMixin,
	],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,
		ItemList,
		ImageUpload,
		TextArea,
		SaveButtonArea,
		BottomButton,
		Modal,
		SkeletonLoadingImage,
		FormatMenu,
		AIPanel,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenIcon: 'decision-icon',
			screenName: 'コンセプトデザイン',
			selectedQuestion: {},
			designLink: 'design',

			selectiveModalId: 'decision-selective-modal',
			selectiveContents: DecisionConst.SELECTIVE_CONTENTS.client,
			confirmDeleteImgModalId: 'confirm-delete-image-modal',
			confirmDeleteModalContents: DecisionConst.CONFIRM_DELETE_CONTENTS,
			plainModalId: 'decision-plain-modal',
			plainContents: DecisionConst.PLAIN_NOTI_CONTENTS,
			imagePreviewModal: 'decision-preview-image-modal',
			pdfPreviewModal: 'decision-preview-pdf-modal',

			currentDeletingImgIndex: undefined,
			currentDeletingQuestion: undefined,

			decisionData: {},
			decisionContent: '',
			displayedImageDatas: [],
			decisionStatus: '',
			lable: '',
			questionList: [],
			imageList: [],
			decisionId: undefined,

			isShowItemList: false,
			showUpload: false,
			isSaveButtonShowed: true,
			imgDataString: '',
			imgComment: '',
			imgFileKey: '',
			showBottomButton: false,
			mailContent: '',
			selectedImageUrl: '',
			selectedImageComment: '',
			disableDecisionTextArea: false,
			isOpenFormatMenuDisabled: false,
			uploadFileParams: {
				role: 'delivery',
				delivery_id: parseInt(this.$route.params.projectId),
			},
			mailTitle: '',
			subject: '',
			isSendCreator: false,

			pdfPreviewer: {
				url: '',
			},
			pdfFileIcon: CommonConst.PDF_FILE_ICON,

			disableEditComment: false,

			isShowFormatMenu: false,
			formatMenuData: {
				concept: '',
				designTastes: [],
				colorPalettes: [],
				fonts: [],
				logoFormats: [],
			},

			// AI Predict
			KEYWORD_TITLES: DecisionConst.KEYWORD_TITLES,

			// Show main content after fetch data from server successfully
			isShowContent: false,

			// Control ClientApp scroll
			isControlScroll: false,
		};
	},
	computed: {
		// Get data from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'clientMode',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		disableUploadImgSaveButton() {
			return !this.imgFileKey;
		},
		disableContentBtnDisabled() {
			if (this.decisionContent) {
				return false;
			} else {
				return true;
			}
		},
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}decision`
						: `${this.projectInfo['process_client_url']}decision`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}decision`
					: `${this.projectInfo['process_client_url']}decision`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
		aiDisabled() {
			return !!this.preview || this.clientMode;
		},

		isFormatMenuReadonly() {
			return !!this.preview || !!this.clientMode;
		},
	},
	watch: {
		imageList(newVal) {
			if (newVal && newVal.length > 0) {
				let quantity = 0;

				newVal.map((imageData) => {
					if (
						imageData['user_id'] === this.userId &&
						imageData['role'] === 'decision'
					) {
						quantity++;
					}
				});

				if (quantity >= DecisionConst.MAX_ITEM) {
					this.showUpload = false;
				} else if (
					this.decisionStatus !== CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM &&
					this.decisionStatus !== CommonConst.SCREEN_STATUS.FINISH
				) {
					this.showUpload = true;
				}
			}
		},

		/**
		 * Update notification red-dot base on screen status
		 * @param {String} newStatus  Screen Status
		 */
		decisionStatus(newStatus) {
			if (newStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
				this.setRedDotNotiy(CommonConst.SCREEN_ID.DECISION);
			} else if (newStatus === CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM) {
				this.disableEditComment = true;
				this.showUpload = false;
				this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.DECISION);
			} else {
				this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.DECISION);
			}
		},

		isShowContent() {
			this.setAutoHeightTextArea(
				'decisionContent__imageItem__comment',
				DecisionConst.TEXT_AREA_MIN_HEIGHT,
				true
			);
		},

		device: {
			handler(newDevice) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'check-device',
						content: {
							device: newDevice,
							orientation: this.orientation,
						},
					},
					room: this.projectId,
				});
				console.log(newDevice, 'color: red');
			},
			immediate: true,
		},

		orientation: {
			handler(newOrientation) {
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'check-device',
						content: {
							device: this.device,
							orientation: newOrientation,
						},
					},
					room: this.projectId,
				});
				console.log(newOrientation, 'color: red');
			},
			immediate: true,
		},

		isControlScroll(newIsControlScroll) {
			this.isOpenFormatMenuDisabled = newIsControlScroll;
			this.$store.commit('setIsControlScrollDecision', newIsControlScroll);
		},

		preview: {
			handler(newPreview) {
				this.isOpenFormatMenuDisabled = !!newPreview;
			},
			immediate: true,
		},

		// clientMode: {
		// 	handler(newClientMode) {
		// 		this.isOpenFormatMenuDisabled = !!newClientMode;
		// 	},
		// 	immediate: true,
		// },
	},
	methods: {
		onConfirm() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		onImageOver(index, userId) {
			if (userId === this.userId && !this.preview)
				this.displayedImageDatas[index].showDeleteBtn = true;
		},

		onImageLeave(index, userId) {
			if (userId === this.userId) this.displayedImageDatas[index].showDeleteBtn = false;
		},

		async onPreviewButtonClick(imgData) {
			if (!imgData.imgUrl || this.clientMode) return;

			if (imgData.type === 'pdf') {
				this.pdfPreviewer.url = imgData.imgUrl;
				console.log('this.this.pdfPreviewer', this.pdfPreviewer);
				this.selectedImageComment = imgData.comment;
				this.$bvModal.show(`${this.pdfPreviewModal}`);
				return;
			}
			if (imgData.imgUrl.includes('.svg')) {
				let base64Img = await this._toDataURL(imgData.imgUrl);
				this.selectedImageUrl = base64Img.replace(
					'binary/octet-stream',
					'image/svg+xml'
				);
			} else {
				this.selectedImageUrl = imgData.imgUrl;
			}
			this.selectedImageComment = imgData.comment;
			this.$bvModal.show(`${this.imagePreviewModal}`);
		},

		/**
		 *
		 * @param {Integer} index Image Index
		 */
		onDeleteImage(index) {
			this.currentDeletingImgIndex = index;
			this.$bvModal.show(`${this.confirmDeleteImgModalId}`);
		},

		async onConfirmDeleteImg() {
			this.$store.commit('setIsAppProcessing', true);

			let index = this.currentDeletingImgIndex;

			this.displayedImageDatas = this._removeItemOfArray(
				this.displayedImageDatas,
				index
			);

			this.imageList = this._removeItemOfArray(this.imageList, index);

			await this._updateDecision(this.projectId, {
				image_list: this.imageList,
				update_user: this.userId,
			});

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_creator',
					content: true,
				},
				room: this.projectId,
			});

			this.currentDeletingImgIndex = undefined;

			this.$store.commit('setIsAppProcessing', false);
		},

		_removeItemOfArray(array, index) {
			let originArray = [...array];
			originArray.splice(index, 1);
			return originArray;
		},

		async onAnswer(data) {
			this.$store.commit('setIsAppProcessing', true);

			const decisionData = { ...this.decisionData };
			const decisionDetails = decisionData['decision_details'];
			const selectedQuestionObj = decisionDetails.find((x) => x.id === data['id']);
			const selectedQuestionImg = selectedQuestionObj['image_file_name'];
			const selectedQuestionImgURL = await this._getPreviewImgUrl(
				selectedQuestionImg,
				CommonConst.ONE_WEEK_SECONDS
			);
			const selectedQuestion = {
				answeredFlag: selectedQuestionObj['answered_flag'],
				comment: selectedQuestionObj['comment'],
				questionContent: selectedQuestionObj['question'],
				imageUrl: selectedQuestionImgURL,
				answerDeadline: moment(selectedQuestionObj['answer_deadline']).format(
					'YYYY/MM/DD'
				),
				questionIndex: selectedQuestionObj['id'],
				imageFileName: selectedQuestionObj['image_file_name'],
				answerContent: selectedQuestionObj['answer_content'],
			};
			this.selectedQuestion = selectedQuestion;
			this.$bvModal.show('answer-modal');

			this.$store.commit('setIsAppProcessing', false);
		},

		async onSendImage() {
			this.$store.commit('setIsAppProcessing', true);

			let id = new Date().getTime();
			let newImgData = {
				imageId: id,
				image: this.imgFileKey,
				comment: this.imgComment,
				showDeleteBtn: false,
				showEditComment: false,
				imgUrl: this.imgDataString,
				userId: this.userId,
				type: this.getFileExtention(this.imgFileKey),
				fileName: this.getFileName(this.imgFileKey),
				role: 'decision',
			};

			this.$refs.imageUpload._revertData();
			this.$refs.imageComment._revertData();

			this.displayedImageDatas.push(newImgData);

			let imageUpdated = {
				id: id,
				image: this.imgFileKey,
				comment: this.imgComment,
				user_id: this.userId,
				role: 'decision',
			};
			this.imageList.push(imageUpdated);

			await this._updateDecision(this.projectId, {
				image_list: this.imageList,
				update_user: this.userId,
			});

			this.imgDataString = '';
			this.imgComment = '';
			this.imgFileKey = '';

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_creator',
					content: true,
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);

			this.setAutoHeightTextArea(
				'decisionContent__imageItem__comment',
				DecisionConst.TEXT_AREA_MIN_HEIGHT,
				false
			);
		},

		async onSavePredictedImage(imageInfo) {
			this.$store.commit('setIsAppProcessing', true);

			let id = new Date().getTime();
			let newImgData = {
				imageId: id,
				image: imageInfo.key,
				comment: imageInfo.comment,
				showDeleteBtn: false,
				showEditComment: false,
				imgUrl: imageInfo.url,
				userId: this.userId,
				type: this.getFileExtention(imageInfo.url),
				fileName: this.getFileName(imageInfo.url),
				role: 'decision',
			};

			this.displayedImageDatas.push(newImgData);

			let imageUpdated = {
				id: id,
				image: imageInfo.key,
				comment: imageInfo.comment,
				user_id: this.userId,
				role: 'decision',
			};
			this.imageList.push(imageUpdated);

			await this._updateDecision(this.projectId, {
				image_list: this.imageList,
				update_user: this.userId,
			});

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_creator',
					content: true,
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Remove image data when click close button
		 */
		onRemoveImageData() {
			this.imgDataString = '';
			this.imgFileKey = '';
		},

		onInputRemark(content) {
			// this.displayedImageDatas[content.index].comment = content.comment;
			this.imgComment = content.comment;
		},

		getFileExtention(key) {
			if (key) {
				const arr = key.split('.');
				return arr[arr.length - 1];
			}
		},

		getFileName(key) {
			if (key) {
				const arr = key.split('/');
				return arr[arr.length - 1];
			}
		},

		async onUploadImage(data) {
			this.imgDataString = data.content;
			this.imgFileKey = data.fileKey;
		},

		/**
		 * Update Decision
		 * @param {Integer} projectId Project ID
		 * @param {Object} decisionUpdateData updating data
		 */
		async _updateDecision(projectId, decisionUpdateData) {
			try {
				let response = await DecisionService.update(projectId, {
					doc_content: decisionUpdateData,
				});
				if (!response || response.status !== 200) {
					throw 'Update Decision failed';
				}

				console.log('%c Update Decision successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Change array data
		 * @param {String} type
		 * @param {Integer} idx
		 * @param {Integer} value
		 */
		onFormatMenuDataChange({ type, idx, value }) {
			this.formatMenuData[`${type}`][idx].value = value;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'format-menu-data-change-by-client',
					content: this.formatMenuData,
				},
				room: this.projectId,
			});
		},
		/**
		 * Change text data
		 * @param {String} type
		 * @param {Integer} value
		 */
		onFormatMenuTextChange({ type, value }) {
			this.formatMenuData[`${type}`] = value;
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'format-menu-data-change-by-client',
					content: this.formatMenuData,
				},
				room: this.projectId,
			});
		},

		async onUpdateAnswer(data) {
			this.$store.commit('setIsAppProcessing', true);

			if (data) {
				let decisionData = { ...this.decisionData };
				this.$emit('on-answer-decision');
				this._filterQuestionList(decisionData);
				this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});

				socketClient.send('update_question_list', {
					userId: this.userId,
					projectId: this.projectId,
					content: true,
				});
				// send mail case 26
				this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］］要検討事項の回答が届きました`;
				this.mailContent = `要検討事項の回答が届きました\n(回答期限 ${moment(
					data['answer_deadline']
				).format('YYYY年MM月DD日')})。`;
				this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 要検討事項の回答が届きました`;
				this.isSendCreator = true;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['client_id'],
					this.projectInfo['responsible_user'],
				]);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Modal はい Button triger
		 */
		async onAcceptConfirm() {
			this.$store.commit('setIsAppProcessing', true);

			this.plainContents = DecisionConst.PLAIN_NOTI_CONTENTS;
			let decisionUpdateData = {
				status: CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM,
				confirm_flag: 1,
				//Add dummy reason for change status badge title in Creator Side
				reason: '',
			};
			await this._updateDecision(this.projectId, decisionUpdateData);
			this.showBottomButton = false;
			this.disableEditComment = true;
			this.decisionStatus = CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM;

			console.log('DECISION STATUS________', this.decisionStatus);

			// Send event
			this._updateConfirmFlag(this.projectId);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_answer_confirm',
					content: decisionUpdateData,
				},
				room: this.projectId,
			});
			// send mail case 30
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「詳細決め」確定`;
			this.mailContent = `「詳細決め」を\n確定しました。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「詳細決め」確定`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「詳細決め」確定`;
			this.mailContent = `「詳細決め」を\n確定しました。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「詳細決め」確定`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Modal いいえ Button triger
		 */
		async onDenyConfirm() {
			this.$store.commit('setIsAppProcessing', true);

			let decisionUpdateData = {
				status: CommonConst.SCREEN_STATUS.DENY_CONFIRM,
				//Add dummy reason for change status badge title in Creator Side
				reason: 'Denied',
			};
			await this._updateDecision(this.projectId, decisionUpdateData);
			this.showBottomButton = false;
			this.decisionStatus = CommonConst.SCREEN_STATUS.DENY_CONFIRM;
			this.checkDecisionStatus(this.decisionStatus);
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_answer_confirm',
					content: decisionUpdateData,
				},
				room: this.projectId,
			});
			// send mail case 31
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「詳細決め」が確定されませんでした`;
			this.mailContent = `「詳細決め」が\n確定されませんでした。\n改めて内容を擦り合わせましょう。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「詳細決め」が確定されませんでした`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 「詳細決め」を確定しませんでした`;
			this.mailContent = `「詳細決め」を\n確定しませんでした。\n改めて内容を擦り合わせましょう。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「詳細決め」を確定しませんでした`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Disable button when client confirm
		 */
		_disableElements() {
			this.isSaveButtonShowed = false;
			this.showUpload = false;
			this.disableDecisionTextArea = true;
			this.isOpenFormatMenuDisabled = true;
		},

		/**
		 * Upload image
		 * @param {Object} params request objects
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			try {
				let response = await TransferService.post(params);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: green');
				return response.data.key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Format Date string
		 * @param {String} date
		 * @returns Data in Japanese format 2021年9月18日
		 */
		_formatDateString(date) {
			if (date && typeof date === 'string') {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');

				return `${year}年${month}月${day}日`;
			}
			return date;
		},

		/**
		 * Get Decision Data
		 * @param {Integer} projectId
		 * @param {Object} { isUpdateDicisionContent, isUpdateFormatMenu } indicates the possibility of updating DecisionData and FormatMenuData
		 * @returns
		 */
		async _getDecisionData(projectId, { isUpdateDicisionContent, isUpdateFormatMenu }) {
			let response = await DecisionService.get(projectId);

			if (!response || response.status !== 200) {
				throw 'Get Decision failed';
			}

			if (response.data['confirm_flag'] === 1) {
				this._disableElements();
			} else this.showUpload = true;

			const decisionData = response.data;

			this.decisionData = decisionData;
			this.imageList = decisionData['image_list'];
			this.decisionId = decisionData['id'];
			this.decisionStatus = decisionData['status'];

			if (isUpdateDicisionContent) {
				this.decisionContent = decisionData['decision_content'];
			}
			if (isUpdateFormatMenu) {
				this.formatMenuData = {
					logoNameOrigin: decisionData['logo_name_origin'],
					concept: decisionData['concept'],
					designTastes: decisionData['design_tastes'] || DecisionConst.DESIGN_TASTES,
					colorPalettes: decisionData['color_palettes'] || DecisionConst.COLOR_PALETTES,
					fonts: decisionData['fonts'] || DecisionConst.FONTS,
					logoFormats: decisionData['logo_formats'] || DecisionConst.LOGO_FORMATS,
				};
			}

			this.checkShowFormatMenu(decisionData);

			this.questionList = await this._filterQuestionList(this.decisionData);
			this.checkDecisionStatus(decisionData['status']);
			await this._splitDecisionDataToImageArray(this.imageList);

			this.setAutoHeightTextArea(
				'decisionContent__imageItem__comment',
				DecisionConst.TEXT_AREA_MIN_HEIGHT,
				false
			);
		},

		checkShowFormatMenu(decisionData) {
			const checkShowFormatMenu = Boolean(decisionData['show_format_menu']);
			this.isShowFormatMenu = checkShowFormatMenu;
		},

		/**
		 * Convert decisionData to pairs of image
		 * @param {Object} data Decision data
		 */
		async _splitDecisionDataToImageArray(data) {
			let displayedImageDatas = [];

			if (data && data.length > 0) {
				data.map((imageData) => {
					displayedImageDatas.push({
						imageId: imageData['id'] ? imageData.id : null,
						imageKey: imageData['image'] ? imageData.image : null,
						comment: imageData.comment,
						imgUrl: imageData['url'] ? imageData.url : null,
						showDeleteBtn: false,
						showEditComment: false,
						userId: imageData['user_id'],
						role: imageData.role,
						type: this.getFileExtention(imageData['image']),
						fileName: this.getFileName(imageData['image']),
					});
				});

				this.displayedImageDatas = await this._getPreviewImage(displayedImageDatas);
			} else this.displayedImageDatas = displayedImageDatas;
		},

		/**
		 *
		 * @param {String} imgKey: path of image get from API
		 * @param {Interge} expiration: time of showing image
		 * @returns
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: green');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * get preview image
		 * @param {Array} data imgDatas
		 */
		async _getPreviewImage(data) {
			let displayedImageDatas = [...data];

			// get preview image url
			for (let imageData of displayedImageDatas) {
				if (imageData.imageKey) {
					let imgUrl = await this._getPreviewImgUrl(
						imageData.imageKey,
						CommonConst.ONE_WEEK_SECONDS
					);
					imageData.imgUrl = imgUrl;
				}
			}

			return displayedImageDatas.filter((imageData) => {
				return imageData.imgUrl !== null;
			});
		},

		/**
		 * Filter question list
		 * @param {Object} decisionData
		 */
		async _filterQuestionList(decisionData) {
			let decisionDetails = [];
			let questionList = [];
			decisionDetails = decisionData['decision_details'];
			if (decisionDetails && decisionDetails.length > 0) {
				this.isShowItemList = true;
				for (let i = 0; i < decisionDetails.length; i++) {
					let defaultQuestion = { ...DEFAULT_ITEM };
					defaultQuestion['isChanged'] = Boolean(decisionDetails[i]['answered_flag']);
					if (defaultQuestion['isChanged']) {
						defaultQuestion['gridCols'] = 3;
						defaultQuestion['gridStatus'] = 'normal';
						defaultQuestion['isShowIcon'] = true;
						defaultQuestion['dateData'] = '回答済み';
					} else {
						defaultQuestion['dateData'] = this._filterDate(
							decisionDetails[i]['answer_deadline']
						);
						defaultQuestion['gridCols'] = 2;
						defaultQuestion['gridStatus'] = 'normal';
					}

					defaultQuestion['title'] = decisionDetails[i]['question'];
					defaultQuestion['nonfilterDateData'] = decisionDetails[i]['answer_deadline'];
					defaultQuestion['itemId'] = decisionDetails[i]['id'];

					questionList.push(defaultQuestion);
				}
				return questionList;
			}
			return questionList;
		},

		/**
		 * Filter Date to String
		 * @param {DateTime} date
		 * @returns
		 */
		_filterDate(date) {
			let momentDate = moment(date);
			let year = momentDate.format('YYYY');
			let month = momentDate.format('MM');
			let day = momentDate.format('DD');
			return `回答期限 [${year} 年 ${month} 月 ${day} 日]`;
		},

		/**
		 * Change confirm flag from 0 to 1
		 * @param {String} projectId
		 */
		async _updateConfirmFlag(projectId) {
			try {
				let response = await DecisionService.confirm(projectId);
				if (!response || response.status !== 200) {
					throw 'Confirm Decision failed!';
				}
				console.log('%c Confirm Decision successfully!', 'color: green');
				this._disableElements();
			} catch (error) {
				console.log(error);
			}
		},

		checkDecisionStatus(status) {
			switch (status) {
				case CommonConst.SCREEN_STATUS.WAITING_CONFIRM:
					this.showBottomButton = true;
					this.disableDecisionTextArea = true;
					this.isOpenFormatMenuDisabled = true;
					break;
				case CommonConst.SCREEN_STATUS.ACCEPT_CONFIRM:
					this.disableEditComment = true;
					this.isOpenFormatMenuDisabled = true;
					break;
				case CommonConst.SCREEN_STATUS.DENY_CONFIRM:
					this.disableDecisionTextArea = false;
					this.isOpenFormatMenuDisabled = false;
					break;
			}
		},

		/**
		 * Edit comment
		 * @param {Integer} index index of displayedImageDatas
		 * @param {Integer} imageId image id
		 * @returns
		 */
		async onEditComment(index, imageId) {
			this.$store.commit('setIsAppProcessing', true);

			let editedComment = this.displayedImageDatas[index].comment;
			let tempImageList = [...this.imageList];

			let imageIndex = tempImageList.findIndex((image) => image.id === imageId);

			if (imageIndex < 0) {
				console.log('%c No image found', 'color: red');
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			// Update Decision
			tempImageList[imageIndex].comment = editedComment;
			await this._updateDecision(this.projectId, {
				image_list: tempImageList,
				update_user: this.userId,
			});
			await this._getDecisionData(this.projectId, {
				isUpdateDicisionContent: false,
				isUpdateFormatMenu: false,
			});

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_send_image_to_creator',
					content: true,
				},
				room: this.projectId,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Text area trigger
		 */
		onInputDecision() {
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'decision_content_edit_by_client',
					content: this.decisionContent,
				},
				room: this.projectId,
			});
		},

		/**
		 * Cancel edit comment
		 * @param {Integer} index index of displayedImageDatas
		 */
		async onCancel(index) {
			this.$store.commit('setIsAppProcessing', true);

			this.displayedImageDatas[index].showEditComment = false;

			await this._getDecisionData(this.projectId, {
				isUpdateDicisionContent: false,
				isUpdateFormatMenu: false,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Only edit focused comment
		 * @param {Integer} index index of displayedImageDatas
		 */
		onCommentFocus(e, index) {
			if (e.target.readOnly || this.preview) return;

			this.displayedImageDatas.map((imageData, idx) => {
				imageData.showEditComment = idx === index ? true : false;
			});
		},
		/**
		 * Scroll page of PC
		 * @param {Number} scrollRatio
		 */

		scrollToSelectedArea(id) {
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		},

		async _toDataURL(url) {
			return new Promise((resolve, reject) => {
				var xhr = new XMLHttpRequest();
				xhr.onload = function () {
					var reader = new FileReader();
					reader.onloadend = function () {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.onerror = () => {
					reject({
						status: this.status,
						statusText: xhr.statusText,
					});
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},
		updateDecisionContent(newContent) {
			this.lable = newContent;
		},
		updateStatus(newContent) {
			if (newContent) {
				this.disableDecisionTextArea = true;
				this.disableEditComment = true;
				this.isOpenFormatMenuDisabled = true;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-client',
						content: true,
						lable: this.lable,
					},
					room: this.projectId,
				});
			} else {
				this.disableDecisionTextArea = false;
				this.disableEditComment = false;
				this.isOpenFormatMenuDisabled = false;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-client',
						content: false,
						lable: this.lable,
					},
					room: this.projectId,
				});
			}
		},
		updateStatusComode(newContent, inputPrompt) {
			this.lable = inputPrompt;
			if (newContent) {
				this.disableDecisionTextArea = true;
				this.disableEditComment = true;
				this.isOpenFormatMenuDisabled = true;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-client',
						content: true,
						lable: inputPrompt,
					},
					room: this.projectId,
				});
			} else {
				this.disableDecisionTextArea = false;
				this.disableEditComment = false;
				this.isOpenFormatMenuDisabled = false;
				socketClient.send('data_transfer', {
					data: {
						user_id: this.userId,
						event_name: 'update-status-generate-text-by-client',
						content: false,
						lable: inputPrompt,
					},
					room: this.projectId,
				});
			}
		},
	},

	async created() {
		socketClient.listen('new_question_list_updated', async (data) => {
			this.$store.commit('setIsAppProcessing', true);

			if (data) {
				await this._getDecisionData(this.projectId, {
					isUpdateDicisionContent: false,
					isUpdateFormatMenu: false,
				});
			}

			this.$store.commit('setIsAppProcessing', false);
		});

		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'send_images_to_decision':
					this.$store.commit('setIsAppProcessing', true);

					var options = {
						isUpdateDicisionContent: false,
						isUpdateFormatMenu: false,
					};

					if (data?.content?.updateDecisionContent) {
						options = {
							isUpdateDicisionContent: true,
							isUpdateFormatMenu: false,
						};
					}

					await this._getDecisionData(this.projectId, options);

					this.$store.commit('setIsAppProcessing', false);
					break;

				case 'decision_content_edit_by_creator':
					this.decisionContent = data?.content;
					break;

				case 'decision_send_confirm':
					if (data?.content) {
						this.showBottomButton = true;
						this.decisionStatus = CommonConst.SCREEN_STATUS.WAITING_CONFIRM;
						this.checkDecisionStatus(this.decisionStatus);
					}
					break;

				case 'decision_send_image_to_client':
					if (data?.content) {
						this.$store.commit('setIsAppProcessing', true);

						await this._getDecisionData(this.projectId, {
							isUpdateDicisionContent: false,
							isUpdateFormatMenu: false,
						});

						this.$store.commit('setIsAppProcessing', false);
					}
					break;

				// handle socket when client upload image in readonly and clientMode
				case 'decision_send_image_to_creator':
					if (!!this.preview || this.clientMode) {
						this.$store.commit('setIsAppProcessing', true);

						if (data.content) {
							await this._getDecisionData(this.projectId, {
								isUpdateDicisionContent: false,
								isUpdateFormatMenu: false,
							});
						}

						this.$store.commit('setIsAppProcessing', false);
					}
					break;

				case 'format-menu-data-change-by-creator':
					if (data.content) {
						this.formatMenuData = data.content;
					}
					break;
				case 'update-status-generate-text-by-creator':
					if (data.content) {
						this.disableDecisionTextArea = true;
						this.disableEditComment = true;
						this.isOpenFormatMenuDisabled = true;
						this.lable = data.lable;
					} else {
						this.disableDecisionTextArea = false;
						this.disableEditComment = false;
						this.isOpenFormatMenuDisabled = false;
						this.lable = data.lable;
					}
					break;

				case 'show-hide-format-menu':
					this.isShowFormatMenu = data?.content;
					this.scrollToTop('#topDiv');
					break;

				case 'allow-scroll':
					this.isControlScroll = data?.content;
					if (this.isControlScroll) {
						this.$refs.aiPanel.clearAll();
					}
					break;

				case 'client-remove-reddot':
					if (!!this.preview || this.clientMode)
						this.removeRedDotNotify(CommonConst.SCREEN_ID.DECISION);
					break;

				default:
					break;
			}
		});

		socketClient.listen('scroll_to_area', async (data) => {
			console.log(data);
			this.scrollToSelectedArea(data.id);
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getDecisionData(this.projectId, {
			isUpdateDicisionContent: true,
			isUpdateFormatMenu: true,
		});

		this.$nextTick(() => {
			this.$store.commit('setIsAppProcessing', false);
			this.isShowContent = true;
		});

		setTimeout(() => {
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'check_scroll_status',
					content: this.isControlScroll,
				},
				room: this.projectId,
			});
		}, 1000);
	},
};
