var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mainContainer"},[_c('div',{staticClass:"mainContainer__container"},[_c('div',{staticClass:"mainContainer__header"},[_c('div',{staticClass:"mainContainer__project"},[_vm._v(" "+_vm._s(_vm.clientInfo['client_name'])+_vm._s(_vm.middleContent)+_vm._s(_vm.projectInfo['project_name'])+" ")]),_c('div',{staticClass:"mainContainer__titleArea"},[_c('ScreenHeader',{attrs:{"screenIcon":_vm.screenIcon,"screenName":_vm.screenName,"isShowProjectInfo":true}})],1),_c('div',{staticClass:"mainContainer__period"},[_c('ProjectDeadline',{attrs:{"startDate":_vm.schedule['decision_start_date'],"endDate":_vm.schedule['decision_end_date'],"isDateRange":true}})],1)]),_c('div',{staticClass:"mainContainer__body mainContainer__body--decision"},[_c('div',{staticClass:"contentBlock decisionContent"},[_c('div',{staticClass:"contentBlock__container decisionContent__container",class:{
						'decisionContent__container--noContent': !_vm.isShowContent,
						'decisionContent__container--noScroll': _vm.isControlScroll,
					},attrs:{"id":"topDiv"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowContent),expression:"isShowContent"}],staticClass:"decisionContent__block"},[_vm._m(0),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.decisionContent),expression:"decisionContent"}],staticClass:"decisionContent__textarea",attrs:{"readonly":_vm.disableDecisionTextArea || _vm.isFormatMenuReadonly},domProps:{"value":(_vm.decisionContent)},on:{"input":[function($event){if($event.target.composing)return;_vm.decisionContent=$event.target.value},_vm.onInputDecision]}}),(_vm.isShowFormatMenu)?_c('FormatMenu',{attrs:{"ideaText":_vm.decisionContent,"formatMenuData":_vm.formatMenuData,"disabled":_vm.isOpenFormatMenuDisabled,"titleCaption":'入力内容はリアルタイムでクライアントに表示されます'},on:{"on-change":_vm.onFormatMenuDataChange,"on-change-text":_vm.onFormatMenuTextChange,"update-decision-content":_vm.updateDecisionContent,"update-status":_vm.updateStatus}}):_vm._e()],1),_c('AIPanel',{ref:"aiPanel",staticClass:"decisionContent__block--AI",attrs:{"decisionContent":_vm.decisionContent,"inputPrompt":_vm.lable,"isShowFormatMenu":_vm.isShowFormatMenu,"formatMenuData":_vm.formatMenuData,"isControlScroll":_vm.isControlScroll,"disabled":_vm.isOpenFormatMenuDisabled},on:{"on-save":_vm.onSavePredictedImage,"update-status":_vm.updateStatusComode}}),_c('div',{staticClass:"decisionContent__block",style:({
							display:
								_vm.isShowContent && _vm.displayedImageDatas.length > 0 ? 'block' : 'none',
						})},[_vm._m(1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayedImageDatas.length > 0),expression:"displayedImageDatas.length > 0"}],staticClass:"decisionContent__imageBlock"},_vm._l((_vm.displayedImageDatas),function(receivedContent,index){return _c('div',{key:index,staticClass:"decisionContent__imageItem",class:{
									'decisionContent__imageItem--pdf': receivedContent.type === 'pdf',
									'--disabled': _vm.clientMode,
								},on:{"mouseover":function($event){return _vm.onImageOver(index, receivedContent.userId)},"mouseleave":function($event){return _vm.onImageLeave(index, receivedContent.userId)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(receivedContent.showDeleteBtn),expression:"receivedContent.showDeleteBtn"}],staticClass:"decisionContent__imageItem__deleteButton",attrs:{"src":require("@/assets/ForComponent/CloseBtn.svg")},on:{"mousedown":function($event){return _vm.onDeleteImage(index)}}}),_c('div',{staticClass:"decisionContent__imageItem__container",class:{
										'decisionContent__imageItem__container--pdf':
											receivedContent.type === 'pdf',
									},on:{"click":function($event){return _vm.onPreviewButtonClick(receivedContent)}}},[_c('img',{staticClass:"decisionContent__imageItem__previewButton",attrs:{"src":require("@/assets/ForComponent/SearchIcon-2.svg"),"alt":"Preview Image"}}),_c('SkeletonLoadingImage',{staticClass:"decisionContent__imageItem__image",class:{
											'decisionContent__imageItem__image--pdf':
												receivedContent.type === 'pdf',
										},attrs:{"imageSrc":receivedContent.type === 'pdf'
												? _vm.pdfFileIcon
												: receivedContent.imgUrl,"imageContain":true}}),_c('label',{directives:[{name:"show",rawName:"v-show",value:(receivedContent.type === 'pdf'),expression:"receivedContent.type === 'pdf'"}],staticClass:"decisionContent__imageItem__fileName"},[_vm._v(" "+_vm._s(receivedContent.fileName)+" ")])],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(receivedContent.comment),expression:"receivedContent.comment"}],staticClass:"decisionContent__imageItem__comment",attrs:{"readonly":_vm.disableEditComment ||
										receivedContent.userId !== _vm.userId ||
										_vm.preview ||
										_vm.clientMode},domProps:{"value":(receivedContent.comment)},on:{"focus":function($event){return _vm.onCommentFocus($event, index)},"input":function($event){if($event.target.composing)return;_vm.$set(receivedContent, "comment", $event.target.value)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(receivedContent.showEditComment),expression:"receivedContent.showEditComment"}],staticClass:"editCommentWrapper"},[_c('button',{staticClass:"editCommentWrapper__editBtn",on:{"click":function($event){return _vm.onEditComment(index, receivedContent.imageId)}}},[_vm._v(" 変更")]),_c('button',{staticClass:"editCommentWrapper__cancelBtn",on:{"click":function($event){return _vm.onCancel(index)}}},[_vm._v(" キャンセル ")])])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowContent && (_vm.showUpload || _vm.displayedImageDatas.length > 0)),expression:"isShowContent && (showUpload || displayedImageDatas.length > 0)"}],staticClass:"decisionContent__block",style:({
							display: _vm.showUpload ? 'block' : 'none',
						}),attrs:{"id":"decision-content"}},[(_vm.showUpload && !_vm.preview && !_vm.clientMode)?_c('div',{staticClass:"contentBlock__title extraPadding",attrs:{"id":"search-image"}},[_c('div',{staticClass:"contentBlock__titleMain"},[_vm._v("参考画像")]),_c('div',{staticClass:"contentBlock__titleCaption"},[_vm._v(" 最大10ファイルまで参考イメージを共有することができます。 ")])]):_vm._e(),(_vm.showUpload && !_vm.preview && !_vm.clientMode)?_c('div',{staticClass:"decisionContent__uploadImageBlock"},[_c('ImageUpload',{ref:"imageUpload",attrs:{"deleteAble":true,"uploadImageAndPdf":true,"params":_vm.uploadFileParams},on:{"on-send-image-data":_vm.onUploadImage,"on-remove-image-data":_vm.onRemoveImageData}}),_c('TextArea',{ref:"imageComment",on:{"on-input-remark":_vm.onInputRemark}})],1):_vm._e(),(_vm.showUpload && !_vm.preview && !_vm.clientMode)?_c('div',{staticStyle:{"width":"100%"}},[_c('SaveButtonArea',{staticClass:"contentBlock__bottom",attrs:{"buttonLabel":'送信',"isSaveButtonShowed":_vm.isSaveButtonShowed,"labelMessage":"を押下しないと、相手に反映されません。","disabled":_vm.disableUploadImgSaveButton},on:{"on-save-button-trigger":_vm.onSendImage}})],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowContent && _vm.isShowItemList),expression:"isShowContent && isShowItemList"}],staticClass:"decisionContent__block"},[_c('ItemList',{attrs:{"itemContainerClass":'item-registration-area-decision',"itemList":_vm.questionList,"itemListTitle":'要検討事項',"descriptionLabel":'クリエイターからの質問に回答しましょう。',"isSelective":true},on:{"on-select-item":_vm.onAnswer}})],1)],1)])]),_c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBottomButton && !_vm.preview && !_vm.clientMode),expression:"showBottomButton && !preview && !clientMode"}],staticClass:"mainContainer__bottom",class:{
					'mainContainer__bottom--liveScreenMinimum': _vm.mobileLiveScreenMinimum,
				}},[_c('BottomButton',{attrs:{"buttonType":'btn-with-text',"screenName":_vm.screenName,"buttonName":'承認'},on:{"on-confirm-button-trigger":_vm.onConfirm}})],1)]),_c('NotificationModal',{attrs:{"plainModalId":_vm.plainModalId,"selectiveModalId":_vm.selectiveModalId,"selectiveContents":_vm.selectiveContents,"plainContents":_vm.plainContents,"isSelective":true},on:{"on-confirm-require":_vm.onAcceptConfirm,"on-declined-require":_vm.onDenyConfirm}}),_c('NotificationModal',{attrs:{"selectiveModalId":_vm.confirmDeleteImgModalId,"selectiveContents":_vm.confirmDeleteModalContents},on:{"on-confirm-require":_vm.onConfirmDeleteImg}}),_c('Modal',{attrs:{"imageUrl":_vm.selectedImageUrl,"imagePreviewModal":_vm.imagePreviewModal}}),_c('Modal',{attrs:{"pdfPreviewer":_vm.pdfPreviewer,"filePreviewModalId":_vm.pdfPreviewModal,"isDownloadAvailable":false},on:{"on-download-pdf":_vm.onDownloadPdfFile}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contentBlock__title",attrs:{"id":"user-requirement"}},[_c('div',{staticClass:"contentBlock__titleMain"},[_vm._v("思いの言語化")]),_c('div',{staticClass:"contentBlock__titleCaption"},[_vm._v(" 入力内容はリアルタイムでクリエイターに表示されます ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"contentBlock__titleWrapper"},[_c('div',{staticClass:"contentBlock__title"},[_c('div',{staticClass:"contentBlock__titleMain"},[_vm._v("参考画像")]),_c('div',{staticClass:"contentBlock__titleCaption"},[_vm._v(" Co-mode AIと参考画像ツールで保存された画像やアップロードした画像を表示 ")])])])
}]

export { render, staticRenderFns }